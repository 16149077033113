import request from '@/utils/request'

// 文章列表
export const articleListAPI = (data) => {
  return request({
    url: '/articleList',
    method: 'POST',
    data
  })
}

// 文章类型下拉
export const articleTypeDropPullAPI = () => {
  return request({
    url: '/classallList'
  })
}

// 删除文章
export const deleteArticlesAPII = (params) => {
  return request({
    url: '/articleDel',
    method: 'GET',
    params
  })
}

// 上传视频
export const UPLOAD_VIDEO = process.env.VUE_APP_BASE_API + '/video'

// 新增文章
export const newArticleAPI = (data) => {
  return request({
    url: '/addArticle',
    method: 'POST',
    data
  })
}

// 文章详情
export const articleDetailsAPI = (params) => {
  return request({
    url: '/articleInfo',
    method: 'GET',
    params
  })
}

// 编辑文章
export const editArticleAPI = (data) => {
  return request({
    url: '/articleSave',
    method: 'POST',
    data
  })
}

// 打印
export const printAPI = (params) => {
  return request({
    url: '/letterinfo',
    method: 'GET',
    params
  })
}
