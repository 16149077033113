<template>
  <div>
    <el-form :model="search">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-form-item label="请选择类型">
            <el-select
              v-model="search.class_id"
              clearable
              placeholder="请选择类型"
            >
              <el-option
                v-for="item in typeArr"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入文章标题">
            <el-input v-model="search.title" placeholder="请输入文章标题" />
          </el-form-item>
        </el-col>

        <el-col :span="2">
          <el-form-item>
            <el-button type="primary" @click="onSearchBtn" :icon="Search"
              >搜索</el-button
            >
          </el-form-item>
        </el-col>

        <el-col :span="2">
          <el-form-item>
            <el-button :icon="CirclePlus" @click="onAddAnArticle">
              添加</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <!-- 表格 -->

    <el-table
      :data="listData"
      border
      style="width: 100%"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column prop="title" label="文章标题" show-overflow-tooltip />
      <el-table-column prop="class_name" label="所属分类" width="100px" />
      <el-table-column prop="sort" label="排序值" width="100px" sortable />
      <el-table-column prop="create_time" label="创建时间" width="200px" />

      <el-table-column label="操作" align="center">
        <template #default="{ row }">
          <el-button type="primary" size="small" @click="onEditBtn(row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="是否删除该文章?"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="deleteBtn(row.id)"
          >
            <template #reference>
              <el-button type="danger" size="small"> 删除 </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->

    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
// articleListAPI
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { Search, CirclePlus } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import {
  articleListAPI,
  deleteArticlesAPII,
  articleTypeDropPullAPI
} from '@/api/article'

const router = useRouter()
/**
 *  表单 S
 */
// 表单数据源
const search = ref({
  class_id: '',
  title: '',
  page: 1,
  limit: 10,
  total: 0
})
// 搜索
const onSearchBtn = () => {
  onListData()
}

// 文章类型下拉
const typeArr = ref([])
const onArticleTypeDropPull = async () => {
  const data = await articleTypeDropPullAPI()
  typeArr.value = data
}
onArticleTypeDropPull()

// 添加文章
const onAddAnArticle = () => {
  router.push('/article/addEdit')
}

/**
 *  表单 E
 */

/*
 *  表格： S
 */

// 列表
const listData = ref([])
const onListData = async () => {
  const { data, total } = await articleListAPI(search.value)
  search.value.total = total
  listData.value = data
}
onListData()

//  编辑
const onEditBtn = (row) => {
  router.push(`/article/addEdit/${row.id}`)
}

// 删除
const deleteBtn = async (id) => {
  await deleteArticlesAPII({ id })
  ElMessage.success('删除文章成功。')
  onListData()
}
/*
 *  表格： E
 */

/**
 *  分页 S
 */

// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onListData()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onListData()
}

/**
 *  分页 E
 */
</script>

<style lang="scss" scoped></style>
